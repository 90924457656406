import React from "react";
import ReactMarkdown from "react-markdown";

import StyledTemplateBox from "./TemplateBox.styled";
import StyledTemplateBoxInner from "./components/TemplateBoxInner.styled";
import StyledTemplateBoxButton from "./components/TemplateBoxButton.styled";
import StyledTemplateBoxImage from "./components/TemplateBoxImage.styled";
import StyledTemplateBoxDetails from "./components/TemplateBoxDetails.styled";
import StyledTemplateBoxTitle from "./components/TemplateBoxTitle.styled";
import StyledTemplateBoxDescription from "./components/TemplateBoxDescription.styled";

import Discount from "@components/Discount";
import Link from "@components/Link";
import Image from "@components/Image";

import { getExcerpt } from "@utils/functions";

interface TemplateBoxProps {}

const TemplateBox: React.FC<TemplateBoxProps> = ({
  description: { shortDescription = "" },
  price: { currentPrice: price, initialPrice },
  name,
  slug,
  thumbnailImages: { thumbnail },
}) => {
  const procent: number = 100 - (price / initialPrice) * 100;

  return (
    <StyledTemplateBox>
      <Link to={`/templates/${slug}`}>
        <StyledTemplateBoxInner>
          {parseInt(initialPrice) > 0 &&
            initialPrice !== price &&
            parseInt(initialPrice) > parseInt(price) && (
              <Discount>{procent.toFixed(1) * 1}% off</Discount>
            )}
          <StyledTemplateBoxImage>
            <Image src={thumbnail} alt={name} title={name} />
          </StyledTemplateBoxImage>
          <StyledTemplateBoxButton>
            {initialPrice !== price && (
              <span className="old-price"> ${initialPrice}</span>
            )}
            <span>${price}</span>
          </StyledTemplateBoxButton>
          <StyledTemplateBoxDetails>
            <StyledTemplateBoxTitle>{name}</StyledTemplateBoxTitle>
            <StyledTemplateBoxDescription>
              <ReactMarkdown
                source={getExcerpt(shortDescription, 20)}
              />
            </StyledTemplateBoxDescription>
          </StyledTemplateBoxDetails>
        </StyledTemplateBoxInner>
      </Link>
    </StyledTemplateBox>
  );
};

export default TemplateBox;

import styled from "styled-components";

const Discount = styled.div`
  position: absolute;
  top: 5px;
  left: 40px;
  color: white;
  transform: translate(-50%, 0);
  border-bottom-right-radius: 3px;
  box-shadow: 0 2px 6px -2px rgba(0, 0, 0, 0.4);
  min-width: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #e74c3c;
  text-align: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 20px;
  z-index: 10;
  &::before {
    content: "";
    width: 0;
    height: 0;
    border-top: 0 solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #a9897f;
    position: absolute;
    bottom: -10px;
    left: 0;
  }
`;

export default Discount;

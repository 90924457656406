import styled from "styled-components";
import Button from "@components/Button";

const TemplateBoxButton = styled(Button)`
  transition: all 0.3s ease-in-out;
  width: 200px;
  height: 60px;
  margin-top: -35px;
  color: white;
  display: flex;
  justify-content: center;
  justify-self: center;
  border-radius: 50px;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  z-index: 100;
  .old-price {
    margin-right: 10px;
    text-decoration: line-through;
  }
`;

export default TemplateBoxButton;

/**
 * Detect if browser supports passive events
 * @returns {boolean}
 */
export function detectPassiveEvents(): boolean {
  let passive = false;

  if (
    typeof window !== "undefined" &&
    typeof window.addEventListener === "function"
  ) {
    const options = Object.defineProperty({}, "passive", {
      get() {
        passive = true;
      },
    });

    const noop = () => {};
    window.addEventListener("testPassiveEventSupport", noop, options);
    window.removeEventListener(
      "testPassiveEventSupport",
      noop,
      options,
    );
  }

  return passive;
}

const DEFAULT_EXCERPT_LIMIT = 35;
/**
 *
 * @param {string} text
 * @param {number} limit - how many words should the new text have
 */

export const getExcerpt = (
  text: string = "",
  limit: number = DEFAULT_EXCERPT_LIMIT,
): string => {
  return text.split(" ").slice(0, limit).join(" ") + "...";
};

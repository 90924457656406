import styled from "styled-components";
import TemplateBoxImage from "./TemplateBoxImage.styled";

const TemplateBoxInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    ${TemplateBoxImage} {
      opacity: 0.6;
    }
  }
`;

export default TemplateBoxInner;

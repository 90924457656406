import styled from "styled-components";

const TemplateBoxDescription = styled.p`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  line-height: 24px;
  margin: 0;
`;

export default TemplateBoxDescription;

import styled from "styled-components";
import media from "@styles/media";

const TemplateBox = styled.div`
  width: 300px;
  margin-right: 20px;

  ${media.toPhone`
    width: 100%;
    margin: 0;
  `}
  a {
    text-decoration: none;
    color: #3c3c3c;
    display: inline-block;
    height: 100%;
    width: 100%;
    &:hover {
      opacity: 1;
    }
  }
`;

export default TemplateBox;

import styled from "styled-components";

const TemplateBoxDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  padding-bottom: 10px;
  text-align: center;
  border: 1px solid #e9e9e9;
  border-top: none;
  box-shadow: 0 1px 2px 0 rgba(25, 25, 25, 0.1);
`;

export default TemplateBoxDetails;

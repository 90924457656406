import styled from "styled-components";

const TemplateBoxTitle = styled.h4`
  font-size: 24px;
  margin: 10px 0;
  height: 70px;
  line-height: 28px;
  display: flex;
  align-items: center;
  font-weight: 500;
`;

export default TemplateBoxTitle;

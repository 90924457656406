import styled from "styled-components";

const TemplateBoxImage = styled.div`
  height: 200px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  div {
    height: 200px;
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
  img {
    transition: all 0.2s ease-in-out;
    border-top-right-radius: 10%;
    border-top-left-radius: 10%;
    width: 100%;
    z-index: 5;
    height: 200px;
    object-fit: cover;
  }
`;

export default TemplateBoxImage;

import React from "react";
import styled, { css } from "styled-components";
import media from "@styles/media";

export enum ButtonTypes {
  Primary = "Primary",
  Secondary = "Secondary",
}

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  stylingType?: ButtonTypes;
  color?: string;
  isDisabled?: boolean;
}

const Button = styled.button<ButtonProps>`
  width: 250px;

  height: 60px;
  color: white;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 3px solid transparent;
  background-size: 200% auto;
  border-radius: 1em;
  text-decoration: none;
  background: transparent
    linear-gradient(to right, #13b0e5 0%, #22259b 100%) 0% 0%
    no-repeat padding-box;
  ${media.toLaptopSmall`
    width: 180px;
    font-size: 16px;
  `}
  &:hover {
    background-position: right center;
    color: rgba(white, 0.8);
  }
  ${props =>
    props.stylingType === ButtonTypes.Secondary &&
    css`
      display: flex;
      align-items: center;
      width: 247px;
      ${media.toLaptopSmall`
        width: 177px;
      `}
      height: 57px;

      position: relative;
      box-sizing: border-box;

      color: #fff;
      background: white;
      background-clip: padding-box;
      border: solid 3px transparent;
      border-radius: 1em;
      color: #3666ba;
      transition: all 0.3s ease-in-out;
      ${media.toTabletP`
        border: 3px solid #1a7ece;
}     `}
      &:hover {
        background: transparent
          linear-gradient(to right, #13b0e5 0%, #22259b 100%) 0% 0%
          no-repeat padding-box;
        color: white;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        margin: -3px;
        border-radius: inherit;
        background: transparent
          linear-gradient(to right, #13b0e5 0%, #22259b 100%) 0% 0%
          no-repeat padding-box;
      }
    `}
`;

Button.defaultProps = {
  stylingType: ButtonTypes.Primary,
};

export default Button;
